<template>
  <fw-layout :back-to="backToPath" mobile-ready :main-sidebar="false" loading-title="UC Competitions">
    <template #main-content>
      <div class="xl:max-w-screen-lg mx-auto px-5 flex flex-col gap-5">
        <BannerLeague
          v-if="league && !loadingEdition"
          :league="league"
          :edition="edition.name"
          :sport="sport"
          :num-teams="sport?.stats?.approved_teams ?? 0"
          :num-players="sport?.stats?.total_players ?? 0"
          :show-edition="true"
          :show-teams="true"
          :show-players="true"
        />
        <div v-else class="h-56 rounded-3xl bg-gray-200 animate-pulse"></div>
        <fw-panel :title="'Torneios'" featured class="mt-10 mb-5">
          <!-- <template #toolbar>
            <span class="flex gap-2">
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" :label="'Por Género'">
                </fw-button-dropdown>
                <b-dropdown-item
                  class="flex gap-1 items-center font-medium"
                  :label="'Masculino'"
                  aria-role="listitem"
                  @click="getTournaments(null, 'M')"
                >
                  Masculino
                </b-dropdown-item>
                <b-dropdown-item
                  class="flex gap-1 items-center font-medium"
                  :label="'Feminino'"
                  aria-role="listitem"
                  @click="getTournaments(null, 'F')"
                >
                  Feminino
                </b-dropdown-item>
                <b-dropdown-item
                  class="flex gap-1 items-center font-medium"
                  :label="'Misto'"
                  aria-role="listitem"
                  @click="getTournaments(null, null)"
                >
                  Misto
                </b-dropdown-item>
              </b-dropdown> -->
          <!-- Not usefull filter -->
          <!-- <b-dropdown v-if="isAcademic" aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" :label="'Por Núcleo'">
                </fw-button-dropdown>
                <b-dropdown-item
                  :key="'remove'"
                  class="flex gap-1 items-center font-medium"
                  :label="'Ver todos'"
                  aria-role="listitem"
                  @click="getTournaments(null, null)"
                >
                  Ver todos
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(organicUnit, key) in organicUnits"
                  :key="key"
                  class="flex gap-1 items-center font-medium"
                  :label="'Masculino'"
                  aria-role="listitem"
                  @click="getTournaments(key, null)"
                >
                  {{ organicUnit.name }}
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template> -->
          <fw-panel-info
            v-if="!loadingTournaments && tournaments.length == 0"
            type="basic"
            simple
            clean
            class="text-center text-gray-500"
          >
            Não existem torneios a mostrar.
          </fw-panel-info>
          <LoadingPlaceholder v-if="loadingTournaments" />
          <div v-else-if="tournaments.length > 0" class="flex flex-col gap-5">
            <div class="flex flex-col gap-2">
              <div class="flex gap-5 text-gray-500 text-xs font-semibold px-5">
                <div class="w-80 flex-shrink-0">Nome do torneio</div>
                <div class="w-28 flex-shrink-0 pl-1">Género</div>
                <div class="flex-1"></div>
              </div>
              <div class="bg-white rounded overflow-hidden">
                <div
                  v-for="(tournament, t) in tournaments"
                  :key="tournament.key"
                  class="flex gap-6 pr-5 pl-5 pt-1.5 pb-1.5 items-center text-sm cursor-pointer hover:bg-gray-100"
                  :class="{
                    'border-b border-gray-100': t < tournaments.length - 1
                  }"
                  @click="goToTournament(tournament.key)"
                >
                  <div class="font-bold w-80 flex-shrink-0 overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {{ tournament.title[language] }}
                  </div>
                  <div class="w-28 flex-1 pl-1">
                    {{ tournament.gender == 'M' ? 'Masculino' : tournament.gender == 'F' ? 'Feminino' : 'Misto' }}
                  </div>
                  <div
                    v-if="tournament.is_signup_open"
                    class="text-xs text-primary text-left flex gap-2 items-center justify-center"
                  >
                    <fw-dot :color="'primary'" inline ping />
                    <span>{{ $t(`signupsOpen`) }}</span>
                  </div>

                  <div class="flex gap-2 text-gray-500 font-semibold text-xs items-center">
                    <div class="flex items-center">
                      <ApprovedTeamsIcon class="h-10 w-10"></ApprovedTeamsIcon>
                      {{ tournament.stats?.approved_teams ?? 0 }}
                    </div>
                    <div class="flex items-center">
                      <ParticipantsIcon class="h-10 w-10"></ParticipantsIcon>
                      {{ tournament.stats?.total_participants ?? 0 }}
                    </div>
                  </div>

                  <div class="flex gap-2 text-gray-200 font-semibold text-xs items-center">
                    <PrizeIcon
                      class="h-4 w-4"
                      :class="{ 'juc-text-color': !!tournament?.stages?.final?.winner_team }"
                    ></PrizeIcon>
                    <UnkownTeamIcon v-if="!tournament?.stages?.final?.winner_team" class="h-6 w-6"></UnkownTeamIcon>
                    <TeamAvatar v-else :team="tournament?.stages?.final?.winner_team" size="xs"></TeamAvatar>
                  </div>

                  <fw-icon-chevron-right class="h-5 w-5 text-gray-700"></fw-icon-chevron-right>
                </div>
              </div>
            </div>
          </div>
        </fw-panel>
        <fw-panel :title="'Equipas em competição'" featured class="mt-10 mb-5">
          <div
            v-if="teams == null"
            class="grid animate-pulse p-5 gap-5 grid-cols-2 sm:grid-cols-4 md:grid-cols-6 bg-white rounded-md"
          >
            <div class="rounded-lg bg-gray-200 h-48"></div>
            <div class="rounded-lg bg-gray-200 h-48"></div>
            <div class="rounded-lg bg-gray-200 h-48"></div>
            <div class="rounded-lg bg-gray-200 h-48"></div>
          </div>
          <PanelEmptyTeams v-else-if="teams.teams.length == 0" :personal="false" />
          <div v-else class="grid p-5 gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-6 bg-white rounded-md">
            <CardTeam v-for="team in teams.teams" :key="team.key" :team="team" class="overflow-visible" />
          </div>
        </fw-panel>

        <fw-panel-info debug label="Data (raw)">
          <json-viewer :value="{ teams, edition, tournaments, sport }"></json-viewer>
        </fw-panel-info>
        <BannerSportUC />
      </div>
    </template>
  </fw-layout>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import BannerLeague from '@/components/banners/BannerLeague.vue'
import BannerSportUC from '@/components/banners/BannerSportUC.vue'
import ApprovedTeamsIcon from '@/components/icons/ApprovedTeamsIcon.vue'
import ParticipantsIcon from '@/components/icons/ParticipantsIcon.vue'
import PanelEmptyTeams from '@/components/panels/PanelEmptyTeams.vue'
import CardTeam from '@/components/cards/CardTeam.vue'
import PrizeIcon from '@/components/icons/PrizeIcon.vue'
import UnkownTeamIcon from '@/components/icons/UnkownTeamIcon.vue'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder.vue'

export default {
  components: {
    BannerLeague,
    BannerSportUC,
    ApprovedTeamsIcon,
    ParticipantsIcon,
    PanelEmptyTeams,
    CardTeam,
    PrizeIcon,
    UnkownTeamIcon,
    LoadingPlaceholder
  },
  data() {
    return {
      loadingTournaments: true,
      loadingTeams: true,
      loadingEdition: true,
      league: null,
      tournaments: [],
      tournamentsPagination: null,
      edition: null,
      teams: null,
      teamsPagination: null,
      // organicUnits: {},
      query: {}
    }
  },

  computed: {
    isAcademic() {
      return this.league?.type == 'academic'
    },

    sports() {
      return this.league?.sports ?? []
    },

    sport() {
      return this.sports.find(sport => sport.key === this.sportKey)
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language ?? 'pt'
    },

    leagueKey() {
      return this.$route.params.key
    },

    sportKey() {
      return this.$route.params.sportKey
    },

    view() {
      return null //this.$route.meta.view
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    backToPath() {
      return this.$router.resolve({
        name: 'league-view',
        params: {
          editionKey: this.editionKey,
          key: this.leagueKey
        }
      }).href
    }
  },

  mounted() {
    this.getLeagueDetails()
    this.getTournaments()
    this.getTeams()
  },

  methods: {
    async getLeagueDetails() {
      this.loadingEdition = true
      console.log('getLeagueDetails :>> ', this.leagueKey, this.editionKey)
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getLeagueDetails(this.editionKey, this.leagueKey)
          console.log('getLeagueDetails :>> ', response)
          this.league = response.league
          // if (this.isAcademic) this.getOrganicUnits()
          this.edition = response.edition
        },
        () => {
          setTimeout(() => {
            this.loadingEdition = false
          }, 750)
        }
      )
    },

    async getTournaments(organicUnit = null, gender = null) {
      this.loadingTournaments = true

      utils.tryAndCatch(
        this,
        async () => {
          const payload = {
            edition_keys: [this.editionKey],
            league_keys: [this.leagueKey],
            sport_keys: [this.sportKey]
          }

          if (organicUnit) {
            payload.org_unit_keys = [organicUnit]
          }
          if (gender) {
            payload.gender = [gender]
          }
          const response = await this.api.getTournaments(payload)
          console.log('getTournaments :>> ', response)
          this.tournaments = response.tournaments
          console.log('this.tournaments :>> ', this.tournaments)
          this.tournamentsPagination = response.pagination
        },
        () => {
          setTimeout(() => {
            this.loadingTournaments = false
          }, 750)
        }
      )
    },

    async getTeams() {
      this.loadingTeams = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTeams({
            edition_keys: [this.editionKey],
            league_keys: [this.leagueKey],
            sport_keys: [this.sportKey]
          })
          console.log('getTeams :>> ', response)
          this.teams = response.teams
        },
        () => {
          setTimeout(() => {
            this.loadingTeams = false
          }, 750)
        }
      )
    },

    goToTournament(tournamentKey) {
      this.$router.push({
        name: 'tournament-view',
        params: {
          key: this.leagueKey,
          tournamentKey: tournamentKey,
          editionKey: this.editionKey
        }
      })
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "signupsOpen": "Inscrições abertas",
    "stages": {
      "final": "Final",
      "intragroups": "Intra-Núcleos",
      "intergroups": "Inter-Núcleos"
    }
  },
  "en": {
    "signupsOpen": "Signups open",
    "stages": {
      "final": "Final",
      "intragroups": "Intra-Núcleos",
      "intergroups": "Inter-Núcleos"
    }
  }
}
</i18n>
